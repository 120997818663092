import { lazy, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useApp, gTagSet, Loading, ScrollRestoration } from '@laboratoria/sdk-react';
import PageTracker from './PageTracker';

// Dynamic lazy imports for code splitting based on routes.
const AdmissionRoutes = lazy(() => import('../AdmissionRoutes'));
const BootcampRoutes = lazy(() => import('../BootcampRoutes'));
const CountriesRoutes = lazy(() => import('../CountriesRoutes'));
const PlacementRoutes = lazy(() => import('../PlacementRoutes'));
const TeamRoutes = lazy(() => import('../TeamRoutes'));
const UsersRoutes = lazy(() => import('../UsersRoutes'));
const Dashboard = lazy(() => import('../Dashboard'));
const SignIn = lazy(() => import('../SignIn'));
const TopBar = lazy(() => import('../TopBar'));

const App = () => {
  const { auth } = useApp();

  useEffect(() => {
    if (typeof auth.user === 'undefined' || auth.user === null) {
      return;
    }

    // Set UID in GA4 session and add to future GA4 events.
    // Previous session events will be associated with the UID.
    gTagSet('user_id', auth.user.uid);
  }, [auth.user]);

  if (auth.user === undefined) {
    return <Loading />;
  }

  if (auth.user && !auth.user.isStaff) {
    auth.signOut();
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Suspense fallback={<Loading />}>
        {!auth.user
          ? <SignIn />
          : (
            <Router>
              <PageTracker />
              <ScrollRestoration />
              <TopBar />
              <Routes>
                <Route path="/admission/*" element={<AdmissionRoutes />} />
                <Route path="/bootcamp/*" element={<BootcampRoutes />} />
                <Route path="/placement/*" element={<PlacementRoutes />} />
                <Route path="/team/*" element={<TeamRoutes />} />
                <Route path="/users/*" element={<UsersRoutes />} />
                <Route path="/countries/*" element={<CountriesRoutes />} />
                <Route path="/" element={<Dashboard />} />
              </Routes>
            </Router>
          )
        }
      </Suspense>
    </LocalizationProvider>
  );
};

export default App;
