import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gTagEvent } from '@laboratoria/sdk-react';

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    gTagEvent('page_view');
  }, [location]);

  return null;
};

export default PageTracker;