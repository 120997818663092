import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppProvider } from '@laboratoria/sdk-react';
import App from './components/App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppProvider App={App} productionHostname="admin.laboratoria.la" />
  </React.StrictMode>
);
